<template>
    <div class="app-main__inner">
        <div class="container mb-3">
            <div class="row">
                <div class="col-md-12 bg-white p-3">
                    <h2 class="page-title">My Accounts</h2>
                    <div class="row space-y-1">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="inputCompanyName" class="text-xs mb-1 col-form-label font-bold  whitespace-nowrap">{{ $t('compName') }}</label>
                                <div>
                                    <input  type="text" v-model="billing.name" class="form-control input-shadow-box">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="city" class="col-form-label font-bold whitespace-nowrap text-xs mb-1">{{ $t('city') }}</label>
                                <div>
                                    <input type="text" v-model="billing.address.city" class="form-control input-shadow-box">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="inputCompanyNumber" class="col-form-label font-bold whitespace-nowrap text-xs mb-1">{{ $t('compNumber') }}</label>
                                <div>
                                    <input type="text" v-model="billing.company_id" class="form-control input-shadow-box">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="city" class="col-form-label font-bold whitespace-nowrap text-xs mb-1">{{ $t('phoneNum') }}</label>
                                <div>
                                    <input type="text" v-model="billing.address.phone_mobile" class="form-control input-shadow-box">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="inputCompanyAddress" class="col-form-label font-bold whitespace-nowrap text-xs mb-1">{{ $t('billingAddress') }}</label>
                                <div>
                                    <input type="text" v-model="billing.address.address_line_one" class="form-control input-shadow-box">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="email" class="col-form-label font-bold whitespace-nowrap text-xs mb-1">{{ $t('email')}}</label>
                                <div>
                                    <input type="email" v-model="billing.email" class="form-control input-shadow-box">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="inputCompanyZipcode" class="col-form-label font-bold whitespace-nowrap text-xs mb-1">{{ $t('zip') }}</label>
                                <div>
                                    <input type="text" v-model="billing.address.zip_code" class="form-control input-shadow-box">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="invoiceEmail" class="col-form-label font-bold whitespace-nowrap text-xs">{{ $t('invEmail') }}</label>
                                <div>
                                    <input type="email" v-model="billing.invoiceEmail" class="form-control input-shadow-box">
                                </div>
                            </div>
                        </div>
                        <div class="text-center col-md-12">
                            <span>
                                <button @click="updateAccount" :disabled="updating" class="btn btn-theme-custom btn-primary btn-wide cursor-not-allowed">
                                    <span v-if="updating" class="flex align-items-center space-x-3">
                                        <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {{ $t('updating') }}
                                    </span>
                                    <span v-else>{{ $t('update') }}</span>
                                </button>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                    </div>
                </div>
            </div>
        </div>


        <!-- <el-dialog
        :visible.sync="paymentCardModal"
        width="40%"
        :handleClose="handleClose"
        center>
        <div class="container">
            <div class="row">
                <div id="card-element" class="col-md-12">

                </div>
            </div>
        </div>
        </el-dialog> -->

        <Notification />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    // import Paginate from 'vuejs-paginate'
    // import i18n from './../../plugins/i18n'
    // import DownloadTableManager from './../../customComponent/DownloadTableManager'

    export default {
        name : 'accounts',
        components : {
            Notification : () => import('./../../customComponent/NotificationAlert'),
        },
        data () {
            return {
                PaymentType : null,
                billing : {
                    name : '',
                    company_id : '',
                    address : {
                        address_line_one : '',
                        zip_code : '',
                        city : '',
                        phone_mobile : '',
                    },
                    email : '',
                    invoiceEmail : ''
                },
                history : [],
                copyHistory : [],
                updating : false,
                paymentCardModal : false,
                processingCard : false,
                processingInvoice : false,
                loading : true
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'USER_TOKEN',
                USER_REFRESH : 'USER_REFRESH',
                GET_USER_COMPANY_ID : 'GET_USER_COMPANY_ID',
                GET_COMPANY_BILLING : 'GET_COMPANY_BILLING',
                GET_PAYMENT_TYPE : 'GET_PAYMENT_TYPE'
            })
        },
        mounted () {

        },
        methods : {
            updateAccount () {

            }
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.custom-btn-pay{
    background-color: #F167CA !important;
    border-color: #F167CA !important;
    font-weight: 800;
}
.custom-card-section{
    box-shadow: 0 0.46875rem 2.1875rem rgba(4,9,20,0.03),0 0.9375rem 1.40625rem rgba(4,9,20,0.03),0 0.25rem 0.53125rem rgba(4,9,20,0.05),0 0.125rem 0.1875rem rgba(4,9,20,0.03);
    border-width: 0;
    transition: all .2s;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(26,54,126,0.125);
}
.custom-save-btn{
    background-color: #2CDCA7 !important;
    border-color: #2CDCA7 !important;
    font-weight: 800;
}
.override-form-field{
    border: 0;
    border-bottom: 2px solid rgb(64, 138, 235);
    box-shadow: none;
    background-color: #fff !important;
    border-radius: unset !important;
}
.col-form-label {
    padding-top: 1rem !important;
    // font-size: 14px !important;
}
.form-group{
    margin-bottom: 0.4rem !important;
}
.form-input{
    font-size: 0.8rem !important;
    padding: 0.2rem 0.75rem !important;
}
.col-form-label{
    padding-bottom: unset !important;
    padding-top: 0.3rem !important;
}
.app-main .app-main__inner{
    padding-top: 20px !important;
}
.table td{
    padding: 0.1rem 0.55rem !important;
}
.form-control{
    border: 1px solid $form-border !important;
    height: unset !important;
    padding: .175rem .75rem !important;
}
.form-control:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}
.btn-border-all{
    border: 1px solid $theme-secondary-color !important;
    color: $theme-secondary-color !important;
}
</style>
